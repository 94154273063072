import React from 'react'

const SVGLogoSimple = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 25">
      <path d="m22.885 3.285-.015-.016C21.442 1.59 19.59.5 17.163.5c-3.044 0-5.686 1.831-6.977 4.521a8.04 8.04 0 0 0-3.67 3.752C3.851 10.083 2 12.93 2 16.262c0 4.61 3.486 8.238 7.82 8.238 2.112 0 4.084-.766 5.5-2.155l7.941-8.11-.78-.797c-1.187 1.552-3.084 2.465-5.255 2.465-3.728 0-6.572-2.943-6.74-6.78 1.864.164 3.183 1.081 4.275 2.422l8.124-8.26Zm-13.28 3.34a8.68 8.68 0 0 0-.23 1.39 7.48 7.48 0 0 0-1.291.192 6.6 6.6 0 0 1 1.521-1.582Zm5.165 3.353c-1.156-1.078-2.555-1.792-4.257-1.945a7.74 7.74 0 0 1 .515-2.204 6.75 6.75 0 0 1 2.529-.483c1.755 0 3.065.631 4.138 1.645L14.77 9.978Zm-.216 11.618c-1.18 1.15-2.828 1.808-4.67 1.808-3.843 0-6.748-3.125-6.748-7.142 0-2.505 1.082-4.618 2.797-5.885a8.749 8.749 0 0 0-.264 2.134c0 4.61 3.486 8.238 7.821 8.238a8.415 8.415 0 0 0 2.196-.288l-.509.52-.623.615Zm3.683-3.76c-1.18 1.156-2.833 1.817-4.68 1.817-3.843 0-6.748-3.126-6.748-7.143-.006-1.004.18-2 .546-2.93a6.662 6.662 0 0 1 1.995-.462c.176 4.438 3.59 7.88 7.813 7.88a8.342 8.342 0 0 0 2.196-.289l-.509.52-.613.606ZM17.23 1.6c1.755 0 3.065.632 4.137 1.644l-2.924 2.988c-1.31-1.222-2.932-1.978-4.953-1.978a7.444 7.444 0 0 0-1.733.202C12.963 2.694 14.927 1.6 17.23 1.6Z" fill="currentColor" />
    </svg>
  )
}

export default SVGLogoSimple;
