import React, { useEffect, useRef } from 'react';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import { StyledScrollingText } from './ScrollingText.styled';
import { Wrapper } from '../../styles/Wrapper.style';

const Template = ({ data }: MarkdownData<ScrollingTextProp>) => {
  //#region Hooks / Lifecycles
  const canvasRef = useRef<HTMLCanvasElement>();

  const ctx = useRef<CanvasRenderingContext2D>();

  const RAF = useRef<number>(null);

  const width = useRef<number>();

  const textWidth = useRef<number>();

  const height = useRef<number>();

  const sentences = useRef<Point[]>([]);

  const speed = useRef<number>(2);

  const textGutter = useRef<number>(20);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables
  const { scrollingText } = data.markdownRemark?.frontmatter || {};
  //#endregion

  //#region Functions
  const init = (): void => {
    if (typeof window === 'undefined') return;

    ctx.current = canvasRef.current.getContext('2d');
    bindEvents();
    onResize();
    document.fonts.ready.then(() => {
      generateSentencesAray();
      animate();
    });
  }

  const destroy = (): void => {
    unbindEvents();
    cancelAnimationFrame(RAF.current);
    RAF.current = null;
  }

  const bindEvents = (): void => {
    window.addEventListener('resize', onResize);
  }

  const unbindEvents = (): void => {
    window.removeEventListener('resize', onResize);
  }

  const onResize = (): void => {
    const parent: HTMLElement = canvasRef.current.parentElement;
    canvasRef.current.width = width.current = parent.offsetWidth * window.devicePixelRatio;
    canvasRef.current.height = height.current = parent.offsetHeight * window.devicePixelRatio;
    generateSentencesAray();

  }

  const generateSentencesAray = (): void => {
    let existingOffset: number = 0;
    if (sentences.current.length) {
      for (let i = 0; i < sentences.current.length; i++) {
        const x = sentences.current[i].x;
        if (x < existingOffset) {
          existingOffset = x;
        }
      }
    }

    sentences.current = [];
    const _ctx = ctx.current;
    _ctx.beginPath();
    _ctx.font = `600 ${(window.innerWidth * window.devicePixelRatio) * 0.083125}px Inter`

    const textBounding = _ctx.measureText(scrollingText);

    const segments: number = Math.ceil((window.innerWidth * window.devicePixelRatio) / textBounding.width) + 1;

    textWidth.current = textBounding.width;

    for (let i = 0; i < segments; i++) {
      const x = textBounding.width * i + (textGutter.current * i) + existingOffset;
      sentences.current.push({ x });
    }
  }

  const drawText = (): void => {
    for (let i: number = 0; i < sentences.current.length; i++) {
      const sentence = sentences.current[i];
      ctx.current.beginPath();
      ctx.current.font = `600 ${(window.innerWidth * window.devicePixelRatio) * 0.083125}px Inter`;
      ctx.current.fillStyle = '#F9F7EE';
      ctx.current.textBaseline = "middle";
      ctx.current.fillText(scrollingText, sentence.x, height.current / 2);
    }
  }

  const updateText = (): void => {
    for (let i: number = 0; i < sentences.current.length; i++) {
      sentences.current[i].x -= (speed.current * window.devicePixelRatio);
      if (sentences.current[i].x <= (textWidth.current + textGutter.current * window.devicePixelRatio) * -1) {
        let targetX = 0;
        for (let j: number = 0; j < sentences.current.length; j++) {
          if (sentences.current[j].x > targetX) {
            targetX = sentences.current[j].x;
          }
        }
        sentences.current[i].x = targetX + textWidth.current + (textGutter.current * window.devicePixelRatio);
      }
    }
  }

  const animate = (): void => {
    ctx.current.clearRect(0, 0, width.current, height.current);
    updateText();
    drawText();
    RAF.current = requestAnimationFrame(animate);
  }
  //#endregion

  //#region Templating
  return (
    <StyledScrollingText>
      <canvas ref={canvasRef}></canvas>
      <Wrapper>
        <span className="text">{scrollingText}</span>
      </Wrapper>
    </StyledScrollingText>
  )
  //#endregion
}

export const Scrollingtext: React.FC = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/options.md/"}) {
        frontmatter {
          scrollingText
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} />} />
  //#endregion
}

export const ScrollingtextPreview: React.FC<ScrollingTextProp> = (data: ScrollingTextProp) => <Template data={{
  markdownRemark: {
    frontmatter: data
  }
}} />

interface ScrollingTextProp {
  scrollingText: string;
}

interface Point {
  x: number;
}