import styled from 'styled-components/macro';

export const Wrapper = styled.div<WrapperProps>`
  --width: ${p => p.width || 1320}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--gutter);
  ${p => p.gutter !== 0
    ? 'max-width: calc(var(--width) + var(--gutter) * 2);'
    : '--gutter: 0; max-width: var(--width);'
  }
  
`;

interface WrapperProps {
  width?: number;
  gutter?: number;
}