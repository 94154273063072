import { Link } from 'gatsby';
import styled, { css } from 'styled-components/macro';

const DefaultCtaStyle = css`
  --padding: 12px;
  transition: .25s;
  transition-property: background, color;
  display: inline-block;
  background: transparent;
  border: 1px solid var(--pink);
  padding: var(--padding);
  border-radius: 8px;
  font: 300 12px/20px var(--secondaryFont);
  text-decoration: none;
  color: var(--pink);
  
  &.cta--wide { --padding: 8px 50px; }
  &.cta--small { --padding: 8px 23px; }
  &.cta--normal { --padding: 8px 20px; }

  &:hover {
    background: var(--pink);
    color: var(--cream);
  }
`;

export const StyledA = styled.a<CtaProps>`
  ${DefaultCtaStyle}
`;

export const StyledLink = styled(Link)`
  ${DefaultCtaStyle}
`;

export const StyledBtn = styled.button<CtaProps>`
  background: transparent;
  border: none;
  cursor: pointer;
  ${DefaultCtaStyle}
`;

interface CtaProps {
  size?: 'small' | 'normal' | 'wide';
}