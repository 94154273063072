import { Section } from '../../styles/Section.style';
import SVGLogo from '../../styles/svg/logo';
import { Wrapper } from '../../styles/Wrapper.style';
import { desktopHover } from '../../utils/Tools';
import styled, { css } from 'styled-components';
import { Cta } from '../Cta/Cta';

export const StyledHeader = styled.header<StyledHeaderProps>`
  --itemGap: 3.88vw;
  --itemGap: 56px;
  @media (min-width: 1440px) {
  }

  @media (max-width: 1300px) {
    --itemGap: 30px;
  }

  @media (max-width: 1100px) {
    --itemGap: 15px;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  .header-section {
    transition: .25s;
    padding: 16px 0;
    background: var(--background);
    color: var(--color);
    transition: .25s;
    transition-property: color, background;

    .header--cream & {
      --background: var(--cream);
      --color: var(--charcoal);
    }
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.7);
    z-index: -1;

    transition: .55s opacity;

    ${p => p.menuOpened
      ? css`
        pointer-events: all;
        opacity: 1;
      `
      : css`
        pointer-events: none;
        opacity: 0;
      `}
  }

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    top: 100%;
    height: 1px;
    background: var(--cream);
    opacity: .1;
  }

  ${Wrapper} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu__left {
    display: flex;
    align-items: center;
  }

  .logo {
    transition: .25s color;
    display: block;
    color: var(--color);
    svg {
      width: 87px;
      height: 25px;
    }

    &:hover {
      color: var(--pink);
    }
  }

  .items, .links {
    display: flex;
    align-items: center;
  }

  .item {
    display: block;
    margin-left: var(--itemGap);
    &:first-child {
      margin-left: 0;
    }
  }

  .items {
    margin: 0 var(--itemGap) 0 0;
  }

  button.link {
    padding: 0;
    background: transparent;
    border: none;
    font: inherit;
    cursor: pointer;
  }

  .link {
    transition: .25s color;
    color: var(--color);
    display: block;
    &.link {
      font: 12px/20px var(--secondaryFont);
    }

    &:hover {
      --color: var(--pink);
    }
  }

  .active .link {
    --color: var(--pink);
  }

  .burger {
    --hitbox: 24px;
    --width: 16px;
    --thickness: 1px;
    padding: 0;
    margin-right: 20px;
    background: transparent;
    border: none;
    width: var(--hitbox);
    height: var(--hitbox);
    position: relative;
    cursor: pointer;
    display: none;

    &__top, &__bottom {
      position: absolute;
      left: 4px;
      display: block;
      height: var(--thickness);
      width: var(--width);
      background: var(--pink);
      transform-origin: left center;
      transition: .55s cubic-bezier(.22, 1, .36, 1);
      will-change: transform;
    }

    &__top {
      top: 8px;

      ${p => p.menuOpened ? css`
        transform: translate(2px, -2px) rotate(45deg);
      `: null}
    }

    &__bottom {
      bottom: 8px;
      transition-delay: 0.025s;
      ${p => p.menuOpened ? css`
        transform: translate(2px, 2px) rotate(-45deg);
      `: null}
    }

    ${desktopHover(`
      .burger__top, .burger__bottom {
        background: var(--color);
      }
    `)}
  }

  @media (max-width: 991px) {
    --itemGap: 24px;

    .burger {
      display: block;
    }

    .items {
      transition: color .25s, background .25s, transform .55s cubic-bezier(.22, 1, .36, 1);
      display: block;
      padding: 24px var(--gutter);
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      background: var(--background);
      z-index: -1;

      ${p => p.menuOpened
        ? css`
          pointer-events: all;
          transform: translateY(0%);
        `
        : css`
          pointer-events: none;
          transform: translateY(-100%);
        `
      }
    }

    .item {
      display: block;
      margin: 0 0 var(--itemGap);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

interface StyledHeaderProps {
  menuOpened: boolean;
}