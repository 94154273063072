import { graphql, Link, StaticQuery, StaticQueryDocument } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { Section } from '../../styles/Section.style';
import SVGLogoSimple from '../../styles/svg/logo-simple';
import { Wrapper } from '../../styles/Wrapper.style';
import { pxtorem, scrollToSelector } from '../../utils/Tools';
import { StyledFooter } from './Footer.styled';

const Template = ({ data }: MarkdownData<FooterData>) => {
  const { logo_url, footer_links } = data.markdownRemark?.frontmatter || {};

  const isHome: boolean = typeof window !== 'undefined' && window.location.pathname === '/';

  return (
    <StyledFooter>
      <Section bg="black" gutter={pxtorem(48)}>
        <Wrapper>
          <nav className="nav">
            <ul className="items">
              <li className="item">
                <a href={logo_url} className="link link--logo"><SVGLogoSimple /></a>
              </li>
              {footer_links.map(({ link_label, link_url }: FooterLink, i: number) => (
                <li key={`footer_links-${i}`} className="item">
                  {link_url.startsWith('http')
                    ? <a href={link_url} target="_blank" className="link">{link_label}</a>
                    : link_url.startsWith('#')
                      ? isHome
                        ? <button type="button" className="link" onClick={scrollToSelector.bind(null, link_url)}>{link_label}</button>
                        : <Link to={`/${link_url}`} className="link">{link_label}</Link>
                      : <Link to={link_url} className="link">{link_label}</Link>
                  }
                </li>
              ))}
            </ul>
          </nav>
        </Wrapper>
      </Section>
    </StyledFooter>
  )
}

export const Footer: FunctionComponent = () => {
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/options.md/"}) {
        frontmatter {
          logo_url
          footer_links {
            link_label
            link_url
          }
        }
      }
    }
  `
  return (
    <StaticQuery
      query={query}
      render={(data) => <Template data={data} />}
    />
  )
}


export const FooterPreview: React.FC<FooterData> = (data: FooterData) => <Template data={{
  markdownRemark: {
    frontmatter: data
  }
}} />


interface FooterData {
  logo_url: string;
  footer_links: FooterLink[];
}

interface FooterLink {
  link_label: string;
  link_url: string;
}