import { pxtorem } from '../utils/Tools';
import styled from 'styled-components/macro';

export const Section = styled.div<SectionProps>`
  --background: var(--${p => p.bg});
  ${p => {
    switch (p.bg) {
      case 'black':
        return '--color: var(--cream);';
      case 'charcoal':
        return '--color: var(--cream);';
      case 'cream':
        return '--color: var(--charcoal);';
      case 'white':
        return '--color: var(--charcoal);';
      }
  }};
  background: var(--background);
  color: var(--color);
  padding: ${p => p.gutter !== null && typeof p.gutter !== 'undefined' ? p.gutter : 0} 0;
`;

interface SectionProps {
  bg: ThemeBG;
  gutter?: string;
}