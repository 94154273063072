import React, { useEffect, useRef, useState } from 'react';
import { VisibilityManager } from '../../services/VisibilityManager';
import { StyledShowOnScroll, StyledShowOnScrollProps } from './ShowOnScroll.styled';

export default function ShowOnScroll({
  children,
  passive = false,
  visibleClassName = 'visible',
  delay = 0,
  transitionDuration = '1.5s',
  easing = 'cubic-bezier(.22, 1, .36, 1)',
  observerOptions,
  onVisible
}: React.PropsWithChildren<ShowOnScrollProps>): JSX.Element {

  const visibilityManager: VisibilityManager = VisibilityManager.getInstance();

  //#region Hooks / LifecyclesLifecycles
  const visibilitySub = useRef<Symbol>();

  const wrapperRef = useRef<HTMLDivElement>();

  const timeoutRef = useRef<any>();

  const [wrapperClassName, setWrapperClassName] = useState<string>();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    init();

    return () => {
      destroy();
    };
  }, []);
  //#endregion

  //#region Functions
  function init(): void {
    visibilitySub.current = visibilityManager.subscribe(wrapperRef.current, onIntersection, observerOptions);
  }

  function destroy(): void {
    clearTimeout(timeoutRef.current);
    unsubscribeVisibilityObserver();
  }

  function unsubscribeVisibilityObserver(): void {
    if (visibilitySub.current) {
      visibilityManager.unsubscribe(visibilitySub.current);
      visibilitySub.current = null;
    }
  }

  function onIntersection(entry: IntersectionObserverEntry): void {
    if (entry.isIntersecting) {
      timeoutRef.current = setTimeout(() => {
        show();

        if (onVisible) {
          onVisible();
        }
      }, delay);

      visibilityManager.unsubscribe(visibilitySub.current);
    }
  }

  function show(): void {
    setWrapperClassName(visibleClassName);
    setIsVisible(true);
  }
  //#endregion

  return (
    <StyledShowOnScroll
      className={wrapperClassName}
      passive={passive}
      duration={transitionDuration}
      delay={delay}
      easing={easing}
      isVisible={isVisible}
      ref={wrapperRef}
    >
      {children}
    </StyledShowOnScroll>
  );
}

interface ShowOnScrollProps extends Pick<Partial<StyledShowOnScrollProps>, 'delay' | 'passive'> {
  visibleClassName?: string;
  margin?: string;
  transitionDuration?: string;
  easing?: string;
  observerOptions?: IntersectionObserverInit;
  onVisible?(): void;
}
