import SVGLogoSimple from '../../styles/svg/logo-simple';
import { desktopHover } from '../../utils/Tools';
import styled from 'styled-components/macro';

export const StyledFooter = styled.footer`
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    background: var(--black);
    height: 2px;
  }
  .items {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .item {
    display: block;
    margin-right: 35px;
    &:last-child {
      margin: 0;
    }
  }

  .link {
    transition: color .25s;
    font: 400 12px/16px var(--secondaryFont);
    color: var(--color);
    text-decoration: none;
    display: block;

    ${desktopHover(`
      --color: var(--pink);
    `)};

    &--logo {
      --color: var(--pink);
      transition-property: color, opacity;

      svg {
        vertical-align: top;
        color: var(--color);
        width: 25px;
        height: 25px;
      }

      ${desktopHover(`
        opacity: 0.7;
      `)};
    }
  }
  
  button.link {
    padding: 0;
    background: transparent;
    border: none;
    text-rendering: geometricPrecision;
    /* font: inherit; */
    color: inherit;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .items {
      flex-direction: column;
    }

    .item {
      margin: 0 0 24px;
    }
  }
`;