import styled from 'styled-components';

export const StyledShowOnScroll = styled.div<StyledShowOnScrollProps>`
  display: block;

  ${ p => !p.passive ? `
    transition-duration: ${ p.duration };
    transition-delay: ${ p.delay }ms;
    transition-timing-function: ${ p.easing };
    
    ${ !p.isVisible ? `
      opacity: .0001;
      transform: translateY(50px);
      ` : `
      opacity: 1;
      transform: translateY(0);
    ` }
  ` : '' }
`;

export interface StyledShowOnScrollProps {
  passive?: boolean;
  easing: string;
  delay: number;
  duration: string;
  isVisible: boolean;
}
