import { css, DefaultTheme, ThemedCssFunction } from 'styled-components';
import theme from '../styles/theme';

/*
* @description Clamp an input between two number
* @param input
* @param min Min Value
* @param max Max Value
*/
export function Clamp(input: number, min: number, max: number): number {
 return input < min ? min : input > max ? max : input;
}

export const pxtorem = (size: number, base: number = theme.general.fontSize): string => {
  return `${(size / base).toPrecision(4)}rem`;
}

export const keepRatio = (w: number, h: number, useAbsolute: boolean = false): string => {
  return `
  position: ${useAbsolute ? 'absolute' : 'relative'};
  &:before {
    content: '';
    display: block;
    padding-top: ${h * 100 / w}%
  }
  `
}

export const desktopHover = (content: string) => {
  return css`
    @media (hover: hover) {
      &:hover, &:focus {
        ${content}
      }
    }
  `
}

export const MaybeWindow: Window = typeof window !== 'undefined' ? window : null;

export interface OffsetObject {
  top: number;
  left: number;
  right: number;
  bottom: number;
  height?: number;
  width?: number;
}

interface Rect extends ClientRect {
  x: number;
  y: number;
}
export const getOffset = (el: HTMLElement, parent: HTMLElement = document.body): OffsetObject => {
  const rect: Rect = el.getBoundingClientRect() as Rect;
  const rectParent: Rect = parent.getBoundingClientRect() as Rect;

  const parentObj: OffsetObject = {
    left: rectParent.left + window.scrollX,
    right: rectParent.right + window.scrollX,
    top: rectParent.top + window.scrollY,
    bottom: rectParent.bottom + window.scrollY
  };

  return {
    left: rect.left + window.scrollX - parentObj.left,
    right: rect.right + window.scrollX - parentObj.left,
    top: rect.top - parentObj.top + window.scrollY,
    bottom: rect.bottom - parentObj.top + window.scrollY,
    height: rect.height,
    width: rect.width
  };
};

export const lerp2D = (p1: Point, p2: Point, t: number = 0.5): Point => {
  const x: number = p1.x + (p2.x - p1.x) * t;
  const y: number = p1.y + (p2.y - p1.y) * t;
  return {
    x,
    y
  }
}

export const lerp = (p1: number, p2: number, t: number = 0.5): number => {
  const x: number = p1 + (p2 - p1) * t;
  return +x.toFixed(4);
}

interface Point {
  x: number;
  y: number;
}

export const scrollToSelector = (target: string): void => {
  if (typeof window === 'undefined') return;
  const el: HTMLElement = document.querySelector(target);
  if (!el) return;
  
  window.scrollTo(0, window.scrollY + el.getBoundingClientRect().top - 140);
  window.history.pushState(null, null, `#${target.replace('#', '')}`)
} 

export function toHyphenCase(camelString: string): string {
  return camelString.replace(/[A-Z]/g, (match, offset) =>
    (offset > 0 ? '-' : '') + match.toLowerCase()
  );
}

export function slugify(text: string): string {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}