import { DefaultTheme } from 'styled-components';

const theme: AppTheme = {
  general: {
    black: '#000',
    charcoal: '#232323',
    cream: '#F9F7EE',
    white: '#fff',
    pink: '#FF33FF',
    green: '#38CDA3',
    fontSize: 20
  }
}

interface AppTheme extends DefaultTheme {}
export default theme;