import { graphql, Link, StaticQuery, StaticQueryDocument } from 'gatsby';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { scrollToSelector } from '../../utils/Tools';
import { Section } from '../../styles/Section.style';
import SVGLogo from '../../styles/svg/logo';
import { Wrapper } from '../../styles/Wrapper.style';
import DeviceInfo from '../../utils/DeviceInfo';
import { Cta } from '../Cta/Cta';
import { StyledHeader } from './Header.styled';

const Template = ({ data }: MarkdownData<HeaderData>) => {
  //#region Hooks
  const currentBG = useRef<BG>('charcoal');

  const linksRef = useRef<HTMLElement[]>([]);

  const mq = useRef<MediaQueryList>(typeof window !== 'undefined' ? window.matchMedia('(max-width: 991px)') : null);

  const resizeDebounced = useRef<number>();

  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  const isHome: boolean = typeof window !== 'undefined' && window.location.pathname === '/';

  useEffect(() => {
    if (typeof window === 'undefined') return;
    init();

    return () => {
      destroy();
    };
  }, []);
  //#endregion

  //#region Vars
  //#endregion

  //#region Functions
  const init = (): void => {
    if (typeof window === 'undefined') return;
    bindEvents();
    setTimeout(() => { removeActiveLinks() });
  }

  const destroy = (): void => {
    if (typeof window === 'undefined') return;
    unbindEvents();
    currentBG.current = null;

    linksRef.current = null;

    mq.current = null;


    resizeDebounced.current = null;
    clearTimeout(resizeDebounced.current);
  }

  const bindEvents = (): void => {
    window.addEventListener('scroll', onScroll);
    window.addEventListener('easedscroll', onEasedScroll);
    mq.current.addEventListener('change', onMqChange);
    if (!DeviceInfo.check().isMobile) {
      window.addEventListener('resize', onResize);
    }
  }

  const unbindEvents = (): void => {
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', onResize);
    window.removeEventListener('easedscroll', onEasedScroll);
    mq.current.removeEventListener('change', onMqChange);
    if (!DeviceInfo.check().isMobile) {
      window.removeEventListener('resize', onResize);
    }
  }

  const removeActiveLinks = (): void => {
    linksRef.current?.forEach(link => link?.classList.remove('active'));
  }

  const onScroll = (): void => {
    document.body.classList.toggle('header--cream', window.scrollY > 50);
  }

  const onEasedScroll = (): void => {
    setActiveLink();
  }

  const onMqChange = (event: MediaQueryListEvent): void => {
    // Resized back to desktop
    if (!event.matches) {
      setMenuOpened(false);
    }
  }

  const onResize = (): void => {
    clearTimeout(resizeDebounced.current);
    document.body.classList.add('disable-animations');

    resizeDebounced.current = window.setTimeout(() => {
      document.body.classList.remove('disable-animations');
    }, 100);
  }

  const toggleMenu = (): void => {
    setMenuOpened(!menuOpened);
  }

  const setActiveLink = (): void => {
    if (!linksRef.current) return;

    header_links.forEach((link, i: number) => {
      if (!(link.link_url.startsWith('#') || link.link_url.startsWith('/#'))) return;

      const targetElement: HTMLElement = document.querySelector(link.link_url);

      if (!targetElement) return;
      const bounding: DOMRect = targetElement.getBoundingClientRect();

      if (bounding.top <= 200 && bounding.bottom > 0) {
        linksRef.current[i]?.classList.add('active');
      } else {
        linksRef.current[i]?.classList.remove('active');
      }

    });
  }
  //#endregion

  //#region Templating
  const { logo_url, header_cta_link, header_cta_label, header_links } = data.markdownRemark?.frontmatter || {};


  return (
    <StyledHeader menuOpened={menuOpened}>
      <div className="mask" onClick={toggleMenu}></div>
      <div className="header-section">
        <Wrapper className="wrapper">
          <div className="menu__left">
            <button type="button" className="burger" onClick={toggleMenu}>
              <span className="burger__top"></span>
              <span className="burger__bottom"></span>
            </button>

            {logo_url === '/'
              ? <Link to={logo_url} className="logo" title="Chord">
                <SVGLogo />
              </Link>
              : <a href={logo_url} className="logo" title="Chord">
                <SVGLogo />
              </a>
            }
          </div>

          <div className="links">
            <ul className="items">
              {header_links.map((item: HeaderLink, i: number) => {
                const { link_label, link_url } = item;
                return (
                  <li className="item" key={`header-link-${i}`} ref={ref => linksRef.current.push(ref)}>
                    {link_url.startsWith('http')
                      ? <a href={link_url} target="_blank" className="link">{link_label}</a>
                      : link_url.startsWith('#')
                        ? isHome
                          ? <button type="button" className="link" onClick={scrollToSelector.bind(null, link_url)}>{link_label}</button>
                          : <Link to={`/${link_url}`} className="link">{link_label}</Link>
                        : <Link to={link_url} className="link">{link_label}</Link>
                    }
                  </li>
                )
              })}
            </ul>

            <Cta href={header_cta_link}>{header_cta_label}</Cta>
          </div>
        </Wrapper>
      </div>
    </StyledHeader>
    //#endregion
  )
};


export const Header: FunctionComponent = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/options.md/"}){
        frontmatter {
          logo_url
          header_cta_link
          header_cta_label
          header_links {
            link_label
            link_url
          }
        }
      }
    }
  `;
  //#endregion

  return (
    <StaticQuery
      query={query}
      render={(data) => <Template data={data} />}
    />
  )
}

export const HeaderPreview: React.FC<HeaderData> = (data: HeaderData) => <Template data={{
  markdownRemark: {
    frontmatter: data
  }
}} />

//#region Interfaces
type BG = 'charcoal' | 'cream';

interface HeaderData {
  logo_url: string;
  header_cta_link: string;
  header_cta_label: string;
  header_links: HeaderLink[];
}
interface HeaderLink {
  link_label: string;
  link_url: string;
}
//#endregion