import gsap from 'gsap';
import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import DeviceInfo from '../../utils/DeviceInfo';
import { Clamp } from '../../utils/Tools';

let currentScroll: number = 0;
let targetScroll: number = 0;

const easing: number = 0.1;

const isMobile: boolean = DeviceInfo.check()?.isMobile || false;


export const SmoothScroll: FunctionComponent = ({ children }) => {
  const page = useRef<HTMLDivElement>();

  const pageSetter = useRef<Function>();

  useEffect(() => {
    init();

    return () => {
      destroy();
    };
  }, []);

  const init = (): void => {
    if (isMobile) return;
    if (typeof window === 'undefined') return;

    pageSetter.current = gsap.quickSetter(page.current, 'y', 'px');

    bindEvents();
    fix();
    setBodyHeight();
    document.fonts.ready.then(() => {
      setBodyHeight();
    });
  }

  const destroy = (): void => {
    if (isMobile) return;

    unbindEvents();
    unfix();
    removeBodyHeight();
    gsap.ticker.remove(tick);
  }

  const bindEvents = (): void => {
    window.addEventListener('resize', setBodyHeight);
    document.addEventListener('scroll', onScroll);
  }

  const unbindEvents = (): void => {
    window.removeEventListener('resize', setBodyHeight);
    document.removeEventListener('scroll', onScroll);
  }

  const setBodyHeight = (): void => {
    if (page.current) {
      gsap.set(document.body, { height: page.current.scrollHeight });
    }
  }

  const removeBodyHeight = (): void => {
    gsap.set(document.body, { clearProps: 'all' });
  }

  const onScroll = (): void => {
    targetScroll = window.scrollY * -1;
    gsap.ticker.add(tick);

  }

  const tick = (): void => {

    currentScroll -= (currentScroll - targetScroll) * easing;

    pageSetter.current(currentScroll);
    window.easedScroll = currentScroll;

    if (Math.abs(targetScroll - currentScroll) <= 0.1) {
      pageSetter.current(targetScroll);
      window.easedScroll = targetScroll;

      gsap.ticker.remove(tick);
    }

    window.dispatchEvent(new CustomEvent('easedscroll'));
  }

  const fix = (): void => {
    if (page.current) {
      gsap.set(page.current, { position: 'fixed', width: '100%' });
    }
  }

  const unfix = () => {
    if (page.current) {
      gsap.set(page.current, { clearProps: 'all' });
    }
  }

  return (
    <div ref={page}>
      {children}
    </div>
  )
}