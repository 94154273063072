import { lerp } from '../../utils/Tools';
import styled, {css} from 'styled-components';

export const StyledStackedCircles = styled.div<StyledStackedCirclesProps>`
  --radius: 24.3055vw;
  @media (min-width: 1440px) {
    --radius: 350px;
  }

  will-change: color, transform;
  width: var(--radius);
  height: var(--radius);
  position: relative;
  z-index: -1;
  transform-style: preserve-3d;
  
  .circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 6px solid var(--color);
    @media (max-width: 991px) {
      border-width: 3px;
    }
    border-radius: 100%;

    ${p => [...Array(p.count)].map((el, i: number) => {
      const {count, minOffset, maxOffset} = p;
      const dividedCount: number = 1 / (count - 1);
      const value: number = lerp(minOffset, maxOffset, dividedCount * i);
      return css`
        &:nth-child(${i + 1}) {
          transform: translateZ(${value * 100 / 1440}vw);
          @media (min-width: 1440px) {
            transform: translateZ(${value}px);
          }
        }
      `
    })}
  }
`

interface StyledStackedCirclesProps {
  count: number;
  minOffset: number;
  maxOffset: number;
}