import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


import { StyledStackedCircles } from './StackedCircles.styled';
import { VisibilityManager } from '../../services/VisibilityManager';

let stackedCirclesIndex: number = 0;


const visibilityManager = VisibilityManager.getInstance();
export const StackedCircles: React.FC<StackedCirclesProp> = ({ count = 4, delay = 0, rotate, waitUntilVisible = false, controlledByScroll = false }) => {
  //#region Hooks / Lifecycles
  stackedCirclesIndex++;

  const circleRef = useRef<HTMLDivElement>();

  const visibilitySubscribtion = useRef<Symbol>();

  const gsapTL = useRef<gsap.core.Timeline>();

  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => {
    if (waitUntilVisible) {
      visibilitySubscribtion.current = visibilityManager.subscribe(circleRef.current, onIntersection);
    } else {
      createTL();
      animateIn();
    }
  }

  const destroy = (): void => {
    destroyGSAP();
    if (waitUntilVisible) {
      unsubscribe();
    }
  }

  const unsubscribe = (): void => {
    visibilityManager.unsubscribe(visibilitySubscribtion.current);
    visibilitySubscribtion.current = null;
  }

  const onIntersection = (entry: IntersectionObserverEntry): void => {
    if (entry.isIntersecting) {
      destroyGSAP();
      createTL();
      animateIn();
      unsubscribe();
      window.dispatchEvent(new CustomEvent('resize'));
    }
  }

  const createTL = (): void => {
    gsapTL.current = gsap.timeline({ paused: true });
    gsapTL.current.to(circleRef.current, {
      '--color': "#FF33FF",
      rotationX: rotate.x || 0,
      rotationY: rotate.y || 0,
      rotationZ: rotate.z || 0,
      duration: 2,
      ease: 'power4.inOut',
      delay
    });
  }

  const animateIn = (): void => {
    if (controlledByScroll) {
      gsap.registerPlugin(ScrollTrigger);

      ScrollTrigger.create({
        animation: gsapTL.current,
        trigger: circleRef.current,
        end: 'center 250px',
        scrub: 1
      });

    } else {
      gsapTL.current.play();
    }
  }

  const destroyGSAP = (): void => {
    ScrollTrigger.getAll().map(st => st.kill(false, true));
    gsap.killTweensOf([circleRef.current]);
    gsapTL.current = null;
  }
  //#endregion

  //#region Templating
  return (
    <StyledStackedCircles ref={circleRef} count={count} minOffset={-100} maxOffset={100}>
      {[...Array(count)].map((el, i: number) => <div key={`circle-${stackedCirclesIndex}-${i}`} className="circle"></div>)}
    </StyledStackedCircles>
  )
  //#endregion
}

interface StackedCirclesProp {
  rotate: { x: number, y: number, z: number }
  count?: number;
  delay?: number;
  waitUntilVisible?: boolean;
  controlledByScroll?: boolean;
}