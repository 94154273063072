import React, { FunctionComponent, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import WebFonts from '../../styles/fonts.style';
import GlobalStyles from '../../styles/global.style';
import { scrollToSelector } from '../../utils/Tools';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { Scrollingtext } from '../ScrollingText/ScrollingText';
import { SmoothScroll } from '../SmoothScroll/SmoothScroll';
import '../../styles/fonts.css';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export const Layout: FunctionComponent = ({ children }) => {
  useEffect(() => {
    if (typeof window === 'undefined') return;
    init();
    return () => {
      destroy();
    }
  }, []);

  const init = (): void => {
    if (window.location.hash && window.location.hash !== '') {
      gsap.registerPlugin(ScrollToPlugin);
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        gsap.to(window, { duration: 1, scrollTo: window.location.hash })
      }, 750);
    }
  }

  const destroy = (): void => {
    //
  }

  return (
    <>
      <Helmet
        script={[{
          type: 'text/javascript',
          innerHTML: '!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="r57C1YEfq7faDkSPjRN4oK838rbgdIc0";;analytics.SNIPPET_VERSION="4.15.3";analytics.load("r57C1YEfq7faDkSPjRN4oK838rbgdIc0");analytics.page();}}();'
        }]}
      >
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
      </Helmet>
      <GlobalStyles />
      <Header />
      <SmoothScroll>
        <main style={{ paddingTop: 'var(--header-height)' }}>
          {children}
        </main>
        <Footer />
        <Scrollingtext />
      </SmoothScroll>
    </>
  )
}