import { Wrapper } from '../../styles/Wrapper.style';
import styled from 'styled-components';

export const StyledScrollingText  = styled.div`
  position: relative;
  background: var(--black);
  overflow: hidden;
  
  ${Wrapper} {
    padding: 60px 0;
    border-top: 1px solid var(--cream);
    opacity: 0.15;
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .text {
    font-weight: 600;
    font: 600 8.3125vw var(--primaryFont);
    white-space: nowrap;
    opacity: 0;
  }
`;