import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import React, { useRef } from 'react';
import { StyledPactImage } from './PactImage.styled';

export const PactImage: React.FC<PactImageProp> = ({ image, alt, addMaxWidth = false }) => {
  const isString = useRef<boolean>(typeof image === 'string');

  let img = useRef<IGatsbyImageData>(null)

  let width = useRef<number>(0);

  if (!isString.current) {
    img.current = getImage(image as ImageDataLike);
    const filename: string = img.current.images.fallback.src.split('/').pop();

    if (filename.indexOf('-2x.') > -1 || filename.indexOf('@2x.') > -1) {
      width.current = img.current.width / 2;
    } else {
      width.current = img.current.width;
    }

  }
  //#region Templating
  if (typeof image === 'string' && image) {
    return <img className="previewImg" src={image.split('../static/').join('/')} alt={alt} />
  } else {
    return (
      <StyledPactImage maxWidth={addMaxWidth ? width.current : 0}>
        <GatsbyImage image={(image as GatsbyImageData).childImageSharp.gatsbyImageData} alt={alt} />
      </StyledPactImage>
    )
  }
  //#endregion
}

interface PactImageProp {
  image: ImageDataLike | string;
  alt: string;
  addMaxWidth?: boolean;
}