import { Link } from 'gatsby';
import React from 'react';
import { scrollToSelector } from '../../utils/Tools';
import { StyledA, StyledBtn, StyledLink } from './Cta.styled';

export const Cta: React.FC<CtaProps> = ({ children, href = '', size = 'normal', className }) => {
  const classes: string = `${className} cta--${size}`;

  if (href.startsWith('http')) {
    return <StyledA className={classes} target="_blank" href={href}>{children}</StyledA>
  } else if (href.startsWith('#')) {
    return <StyledBtn className={classes} type="button" onClick={scrollToSelector.bind(null, href)}>{children}</StyledBtn>
  } else {
    return <StyledLink className={classes} to={href}>{children}</StyledLink>
    // return <PageLink to={href}>{children}</PageLink>
  }
}

export interface CtaProps {
  href: string;
  className?: string;
  size?: 'small' | 'normal' | 'wide';
}