import { createGlobalStyle } from 'styled-components';
import { pxtorem } from '../utils/Tools';

const GlobalStyles = createGlobalStyle`
  :root {
    --gutter: 60px;
    --primaryFont: 'Inter', sans-serif;
    --secondaryFont: 'GT-America', sans-serif;
    --black: ${p => p.theme.general.black};
    --charcoal: ${p => p.theme.general.charcoal};
    --cream: ${p => p.theme.general.cream};
    --white: ${p => p.theme.general.white};
    --pink: ${p => p.theme.general.pink};
    --background: var(--charcoal);
    --color: var(--cream);
    --header-height: 70px;

    @media (max-width: 1300px) {
      --gutter: 40px;
    }

    @media (max-width: 991px) {
      --gutter: 20px;
    }

    @media (max-width: 768px) {
      --gutter: 16px;
    }
  }

  *, *:before, &:after {
    box-sizing: inherit;
  }

  html {
    background: var(--background);
    color: var(--color);
    font-size: ${p => p.theme.general.fontSize}px;
    font-family: var(--primaryFont);
    box-sizing: border-box;
    text-rendering: geometricPrecision;
    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }

  body {
    margin: 0;
    font-size: 1rem;
    line-height: ${pxtorem(30)};
  }

  body.disable-animations {
    *,*:before, *:after {
      transition: none !important;
    }
  }

  h1, h2, h3, h4, h5 {
    margin-top: 0;
  }

  input, select, button {
    font: inherit;
    color: var(--color);
  }

  ul,ol {
    margin: 0;
    padding: 0;
  }

  a {
    color: var(--color);
    text-decoration: none;
  }

  main {
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
`;

export default GlobalStyles;