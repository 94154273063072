import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';


const Template = ({ data }: MarkdownData<SEOData>) => {
  //#region Variables
  const { description, title, image } = data.markdownRemark?.frontmatter || {};
  //#endregion

  //#region Templating
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="description" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image && image.publicURL} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
  //#endregion
}

export const StaticSEO: React.FC<SEOData> = (data: SEOData) => {
  return <Template data={{ markdownRemark: { frontmatter: { ...data } } }} />
}

export const SEO: React.FC = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/seo.md/"}) {
        frontmatter {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} />} />
  //#endregion
}

interface SEOData {
  title: string;
  description?: string;
  image?: {
    publicURL: string
  };
}